import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from '../@config/endpoints';
import { CreateOpportunityPayload, OpportunitiesResult, OpportunityEntry, OpportunityStateEntry } from '../@models';
import { Pagination } from '../@config';
import { OpportunityResponseEntry } from '../@models/opportunity/opportunity-response-entry.model';
import { OpportunitySearchFilter } from '../@models/opportunity/opportunity-filter.model';
import { CreateOrUpdateOpportunity } from '../@models/opportunity/split-opportunity.model';

@Injectable({
  providedIn: 'root'
})
export class OpportunitiesService {

  constructor(private http: HttpClient) { }

  getOpportunities(skipCount: number, searchFilter: any): Observable<OpportunitiesResult> {
    return this.http.get<OpportunitiesResult>(ENDPOINTS.getOpportunities(Pagination.maxResultCount,skipCount, searchFilter.payload.filterString, searchFilter.payload.statusId, searchFilter.payload.phaseId, searchFilter.payload.isAll, searchFilter.payload.productFamilyId)).pipe(
      map((respone: any) => respone?.result || []));
  }

  getById(id: number): Observable<OpportunityResponseEntry> {
    return this.http.get<OpportunityResponseEntry>(ENDPOINTS.getOpportunityById(id)).pipe(
      map((respone: any) => respone?.result));
  }

  getAll(skipCount: number, statusId: number, phaseId:number,searchText?: string, isAll?: boolean, productFamilyId?: number): Observable<OpportunityEntry[]> {
    return this.http.get<OpportunityEntry[]>(ENDPOINTS.getOpportunities(Pagination.maxResultCount, skipCount, searchText || '', statusId || 0, phaseId || 0, isAll || false, productFamilyId || 0)).pipe(
      map((respone: any) => respone?.result?.items || []));
  }

  createOrUpdateOpportunity(opportunity: CreateOpportunityPayload): Observable<any> {
    console.debug('🔥 new opportunity payload', opportunity);
    return this.http.post(ENDPOINTS.createOrUpdateOpportunity(), opportunity).pipe(
      map((respone: any) => respone?.result || []));
  }

  splitOpportunity(opportunity: CreateOrUpdateOpportunity): Observable<any> {
    console.debug('🔥 new opportunity payload', opportunity);
    return this.http.post(ENDPOINTS.splitOpportunity(), opportunity).pipe(
      map((respone: any) => respone?.result || []));
  }

  getSplitHistory(id: number): Observable<any> {
    return this.http.get<any>(ENDPOINTS.getSplitHistory(id)).pipe(
      map((respone: any) => respone?.result));
  }

}
