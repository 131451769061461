import { createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { TaskEntry, TaskResult } from '../@models/index';
import { FeedService } from '../@services/index';
import { Injectable, Injector } from '@angular/core';
import { Pagination } from '../@config';

@State<TaskResult>({
  name: 'tasks',
  defaults: {
    totalCount: 0,
    items: [],
    skipCount: 0,
    loading: false,
  }
})
@Injectable()
export class TasksState {

  private static feedService: FeedService

  @Selector()
  static totalItems(state: TaskResult): number {
    return state.totalCount || 0;
  }

  @Selector()
  static loadedItems(state: TaskResult): number {
    return state.items?.length;
  }

  @Selector()
  static getAll(state: TaskResult): TaskEntry[] {
    return state.items || [];
  }

  static getById(id: number) {
    return createSelector([TasksState], (state: TaskResult) => {
      return state.items.find((task: TaskEntry) => task.id === id);
    });
  }

  static hasAny() {
    return createSelector([TasksState], (state: TaskResult) => {
      return state.items?.length > 0;
    });
  }

  constructor(injector: Injector) {
    TasksState.feedService = injector.get(FeedService);
  }

    @Selector()
    static isLoading(state: TaskResult) {
      return state.loading;
    }
  

    @Receiver()
    static loadFirst({ setState, getState }: StateContext<TaskResult>) {
      // Set loading to true before making the API call
      setState({ ...getState(), loading: true });
    
      this.feedService.getTasks(0).subscribe(
        (result: TaskResult) => {
          const newState = { ...result, skipCount: 0, loading: false };
          setState(newState);
        },
        (error) => {
          console.error("Error fetching tasks:", error);
          
          // Ensure loading is set to false even on error
          setState({ ...getState(), loading: false });
        }
      );
    }
  @Receiver()
  static loadNext({ setState, getState }: StateContext<TaskResult>) {
    const state: TaskResult = getState();
    let skipCount: number = state.skipCount + Pagination.skipCount;
    if (skipCount > state.totalCount) {
      skipCount = state.totalCount;
    }
    this.feedService.getTasks(skipCount).subscribe(
      {
        next: (result: TaskResult) => {
          const tasks = [...state.items, ...result.items];
          const newState = { ...result, items: tasks, skipCount };
          setState(newState);
        }
      })
  }
}
