export class OpportunitySearchFilter {
    filterString?: string;
    statusId?: number;
    phaseId?: number;
    isAll:boolean;
    productFamilyId?:number;
    constructor() {
        this.filterString = '';
        this.statusId = 0;
        this.phaseId = 0;
        this.isAll = false;
        this.productFamilyId = 0;
    }
}