import { Selector, State, StateContext, createSelector } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { Injectable, Injector } from '@angular/core';
import { ENDPOINTS } from 'src/app/@config';
import { IdNamePair } from 'src/app/@models';
import { DropdownService } from 'src/app/@services';

@State<IdNamePair[]>({
  name: 'industrySegments',
  defaults: []
})
@Injectable()
export class IndustrySegmentsState {
  private static dropdownService: DropdownService

  static hasAny() {
    return createSelector([IndustrySegmentsState], (state: IdNamePair[]) => {
      return state?.length > 0;
    });
  }

  @Selector()
  static isEmpty(state: IdNamePair[]): boolean {
    return state.length === 0;
  }

  constructor(injector: Injector) {
    IndustrySegmentsState.dropdownService = injector.get(DropdownService);
  }

  @Receiver()
  static loadAll({ setState }: StateContext<IdNamePair[]>) {
    this.dropdownService.getDropdownData(ENDPOINTS.getIndustrySegmentList()).subscribe((data: IdNamePair[]) => setState(data));
  }

  static getNameById(id: number) {
    return createSelector([IndustrySegmentsState], (state: IdNamePair[]) => {
      const item = state.find((item: IdNamePair) => item.id === id);
      return item ? item.name : null;  // Ensure correct property
    });
  }
}
