import { Selector, State, StateContext, createSelector } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { CustomerEntry, CustomerResult } from '../@models';
import { Injectable, Injector } from '@angular/core';
import { CustomersService } from '../@services';
import { Pagination } from '../@config';

@State<CustomerResult>({
  name: 'customers',
  defaults: {
    totalCount: 0,
    items: [],
    skipCount: 0,
    loading: false,
  }
})
@Injectable()
export class CustomersState {

  private static service: CustomersService

  @Selector()
  static totalItems(state: CustomerResult): number {
    return state.totalCount || 0;
  }
  @Selector()
  static loadedItems(state: CustomerResult): number {
    return state.items?.length;
  }

  @Selector()
  static isLoading(state: CustomerResult) {
    return state.loading;
  }

  @Receiver()
  static stopLoading({ setState, getState }: StateContext<CustomerResult>) {
    const state = getState();
    setState({
      ...state,
      loading: false
    });
  }

  static hasAny() {
    return createSelector([CustomersState], (state: CustomerResult) => {
      return state.items?.length > 0;
    });
  }

  @Selector()
  static getAll(state: CustomerResult): CustomerEntry[] {
    return state.items;
  }

  static getCustomerById(id: number) {
    return createSelector([CustomersState], (state: CustomerResult) => {
      return state.items.find((contact: CustomerEntry) => contact.id === id);
    });
  }

  constructor(injector: Injector) {
    CustomersState.service = injector.get(CustomersService);
  }

  @Receiver()
  static loadFirst({ setState, getState }: StateContext<CustomerResult>) {
    setState({ ...getState(), loading: true });
    this.service.getCustomers(0).subscribe(
      (result: CustomerResult) => {
        const newState = { ...result, skipCount: 0, loading: false };
        setState(newState);
      },
      (error) => {
        console.error("Error fetching colleague:", error);

        // Ensure loading is set to false even on error
        setState({ ...getState(), loading: false });
      }
    );
  }
  @Receiver()
  static loadNext({ setState, getState }: StateContext<CustomerResult>) {
    const state: CustomerResult = getState();
    let skipCount: number = state.skipCount + Pagination.skipCount;
    if (skipCount > state.totalCount) {
      skipCount = state.totalCount;
    }
    this.service.getCustomers(skipCount).subscribe(
      {
        next: (result: CustomerResult) => {
          const tasks = [...state.items, ...result.items];
          const newState = { ...result, items: tasks, skipCount };
          setState(newState);
        }
      })
  }
}
