import { AppState } from './app.state';
import { ContactsState } from './contacts.state';
import { EventsState } from './events.state';
import { RemindersState } from './reminder.state';
import { TasksState } from './task.state';
import { NotificationsState } from './notifications.state';
import { ColleaguesState } from './colleagues.state';
import { CustomersState } from './customers.state';
import { LeadsState } from './leads.state';
import { OpportunitiesState } from './opportunities.state';
import { ServiceCasesState } from './service-cases.state';
import { QuotationsState } from './quotations.state';
import { TaskStatusState } from './dropdown/task-status.state';
import { TaskTypeState } from './dropdown/task-type.state';
import { TaskTopicState } from './dropdown/task-topic.state';
import { RecordsState } from './records.state';
import { CountriesState } from './dropdown/countries.state';
import { IncotermsState } from './dropdown/incoterms.state';
import { EventTypesState } from './dropdown/event-types.state';
import { CurrentUserState } from './current-user.state';
import { ProductsState } from './dropdown/products.state';
import { ServiceCaseTypesState } from './dropdown/service-case-types.state';
import { NewQuoteState } from './new-quote.state';
import { AppInfoState } from './app-info.state';
import { QuoteStatusState } from './dropdown/quote-status.state';
import { OpportunityPhaseState } from './dropdown/opportunity-phases.state';
import { ProductsFamilyState } from './dropdown/products-family.state';
import { OpportunityProductStatusState } from './dropdown/opportunity-product-status.state';
import { CompetitorsState } from './dropdown/competitors.state';
import { NewOpportunityState } from './new-opportunity.state';
import { NewLeadState } from './new-lead.state';
import { IndustrySegmentsState } from './dropdown/industry-segments.state';
import { CustomerClustersState } from './dropdown/customer-clusters.state';
import { LeadStatusState } from './dropdown/lead-status.state';
import { LeadSourceState } from './dropdown/lead-source.state';
import { LeadScoreState } from './dropdown/lead-score.state';
import { TradeLanesState } from './dropdown/trade-lanes.state';
import { OpportunityStatusState } from './dropdown/opportunity-status.state';
import { OpportunityFilterState } from './opportunity/opportunity-filter.state';

export const AllStates = [
  AppState,
  AppInfoState,
  TasksState,
  EventsState,
  RemindersState,
  ContactsState,
  NotificationsState,
  ColleaguesState,
  CustomersState,
  LeadsState,
  OpportunitiesState,
  ServiceCasesState,
  QuotationsState,
  TaskStatusState,
  TaskTypeState,
  TaskTopicState,
  RecordsState,
  CountriesState,
  IncotermsState,
  NewQuoteState,
  EventTypesState,
  CurrentUserState,
  ProductsState,
  ServiceCaseTypesState,
  NewQuoteState,
  QuoteStatusState,
  OpportunityPhaseState,
  ProductsFamilyState,
  OpportunityProductStatusState,
  CompetitorsState,
  NewOpportunityState,
  NewLeadState,
  IndustrySegmentsState,
  CustomerClustersState,
  LeadStatusState,
  LeadSourceState,
  LeadScoreState,
  TradeLanesState,
  OpportunityStatusState,
  OpportunityFilterState
]