import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EmitterService } from '@ngxs-labs/emitter';
import { Store } from '@ngxs/store';
import { StateClear } from 'ngxs-reset-plugin';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { tap, catchError, map, switchMap } from 'rxjs/operators';
import { AppInfoState, AppState, AppStateModel } from '../@store';
import { AuthRequest } from '../@models';
import { ENDPOINTS } from '../@config/endpoints';
import { CurrentUserModel } from '../@store/current-user.state';
import { OtpSignIn } from '../@models/otp-sign-in.model';
import { OtpResponse } from '../@models/otp-response.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedIn = new BehaviorSubject<boolean>(false);
  hideFooter = new BehaviorSubject<boolean>(false);

  constructor(private store: Store,
    private http: HttpClient,
    private emitterSerivce: EmitterService,
    private router: Router) { }

  login(user: AuthRequest): Observable<any> {
    return this.http.post<any>(ENDPOINTS.login(), user).pipe(
      tap((response: any) => {
        if (response.result) {
          const payload: AppStateModel = {
            userId: response.result?.userId,
            token: response.result?.accessToken,
            refreshToken: response.result?.refreshToken
          };
          this.emitterSerivce.action(AppState.setAuthDetails).emit(payload as any);
        }
      }),
      catchError(this.handleError),
    );
  }

  getCurrentUser(): Observable<CurrentUserModel> {
    const userId = this.store.selectSnapshot(AppState.userId);
    console.debug('🔥 getting details for user id', userId);
    return this.http.get<CurrentUserModel>(ENDPOINTS.getUserDetails(+userId)).pipe(
      map((response: any) => response.result),
      catchError(this.handleError),
    );
  }

  isAuthenticated(): Observable<boolean> {
    const token = this.store.selectSnapshot(AppState.token);
    
    if (!token) {
      // No token found, returning expired status 
      return of(false); // If no token, consider it expired
    }
  
    try {
      const payload = JSON.parse(atob(token.split('.')[1])); 
      const expiry = payload.exp * 1000;
  
      if (Date.now() < expiry) {
        console.log("Token is still valid.");
        return of(true); // Token is still valid
      } else {
        //  Token has expired, attempting refresh...
  
        const loginType = this.store.selectSnapshot(AppState.loginType);
  
        if (loginType === 'sso') {
          const email = this.store.selectSnapshot(AppState.email);
          const tenancyName = this.store.selectSnapshot(AppState.tenantName);
          const otp = this.store.selectSnapshot(AppState.ssoOtp);
  
          const payload: OtpSignIn = {
            tenancyName,
            email,
            otp
          };
  
          return this.verifySignInOtp(payload).pipe(
            map((response: any) => {
              console.log("OTP sign-in successful", response);
              return true;
            }),
            catchError(error => {
              console.error("OTP verification failed", error);
              return of(true); // Consider token expired if OTP verification fails
            })
          );
        } else {
          return this.refreshToken().pipe(
            map(success => {
              console.log("Refresh token result:", success);
              return success;
            }),
            catchError(error => {
              console.error("Token refresh failed", error);
              return of(false); // Consider token expired if refresh fails
            })
          );
        }
      }
    } catch (error) {
      return of(false); // Consider it expired if decoding fails
    }
  }

  logout(): void {
    this.store.dispatch(
      new StateClear(AppInfoState)
    );
    this.router.navigate(['/login']);
  }

  refreshToken(): Observable<boolean> {
    const refreshToken = this.store.selectSnapshot(AppState.refreshToken);
    if (!refreshToken) {
      return of(false); // Return false if no refresh token exists
    }

    const url = `${ENDPOINTS.refreshToken()}?refreshToken=${encodeURIComponent(refreshToken)}`;
  
    return this.http.post<any>(url,{}).pipe(
      map((response: any) => {
        if (response.result && response.result.accessToken) {
          const payload: AppStateModel = {
            token: response.result.accessToken,
          };
          this.emitterSerivce.action(AppState.setAuthDetails).emit(payload as any);
          return true;
        } else {
          console.error('Invalid refresh token response', response);
          return false;
        }
      }),
      catchError(error => {
        console.error('Error refreshing token:', error);
        return of(false); // Return false on error
      })
    );
  }

  sendSignInOtp(payload: OtpSignIn): Observable<any> {
    return this.http.post<any>(ENDPOINTS.sendSignInOtp(), {
      tenancyName: payload.tenancyName,
      email: payload.email
    });
  }

  verifySignInOtp(payload: OtpSignIn): Observable<OtpResponse> {
    let userId = 0;
    return this.http.post<OtpResponse>(ENDPOINTS.verifySignInOtp(), {
      tenancyName: payload.tenancyName,
      email: payload.email,
      otp: payload.otp
    }).pipe(
      map((response: any) => response.result),      
      tap((response: OtpResponse) => this.setAuthDetails(response, payload)),
      tap((response: any) => userId = response.userId || -1),
      catchError(this.handleError),
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  setAuthDetails(response, ssoDetails: OtpSignIn){
    const payload: AppStateModel = {
      userId: response.userId,
      token: response.accessToken,
      refreshToken: "",
      email: ssoDetails.email,
      tenantName: ssoDetails.tenancyName,
      ssoOtp: ssoDetails.otp,
      loginType: 'sso'
    };
    this.emitterSerivce.action(AppState.setAuthDetails).emit(payload as any);
    this.router.navigateByUrl('/app/tabs/home');
  }
}
