import { Injectable } from "@angular/core";
import { EmitterAction, Receiver } from "@ngxs-labs/emitter";
import { State, StateContext } from "@ngxs/store";
import { OpportunitySearchFilter } from "src/app/@models/opportunity/opportunity-filter.model";

@State<OpportunitySearchFilter>({
    name: 'newOpportunityAdvancedSearch',
    defaults: new OpportunitySearchFilter()
})
@Injectable({
    providedIn: 'root'
})
export class OpportunityFilterState {

    @Receiver()
    static setCurrentState(
        { setState }: StateContext<OpportunitySearchFilter>,
        { payload }: EmitterAction<OpportunitySearchFilter>
    ) {
        setState(payload)
    }

    @Receiver()
    static reset(
        { setState }: StateContext<OpportunitySearchFilter>) {
        const newEntry = new OpportunitySearchFilter();
        newEntry.isAll = false;
        newEntry.statusId = 1;
        setState(newEntry);
    }

}
