import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { Storage } from '@capacitor/storage';
import { Subscription } from 'rxjs';
import { AuthService } from './@services/auth.service';
import { Store } from '@ngxs/store';
import { AppInfoState, AppState } from './@store/index';
import { EmitterService } from '@ngxs-labs/emitter';
import { SideMenuItems } from './@config/menu';
import { AzureNotificationService } from './@services';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {

  loggedIn = false;
  dark = true;
  showSubmenu: boolean = false;

  subscription: Subscription = new Subscription();
  lastBackPress = 0;
  exitDelay = 2000;

  constructor(private menu: MenuController,
    private router: Router,
    private store: Store,
    private authService: AuthService,
    private emitterService: EmitterService,
    private platform: Platform,
    private toastController: ToastController,
  ) {

    const authChanged$ = this.store.select(AppState.isAuthenticated);
    this.subscription.add(authChanged$.subscribe((isAuthenticated: boolean) => {
      this.loggedIn = isAuthenticated;
    }));

    const currentTheme = this.store.selectSnapshot(AppInfoState.darkMode);
    this.updateTheme(currentTheme);

    this.navPages = SideMenuItems;
    // this.menuService.fetchMenuItems().subscribe((data) => {
    //   this.navPages = data;
    // });
    this.initializeApp();

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.handleBackButton();
    });
  }


  menuItemHandler(): void {
    this.showSubmenu = !this.showSubmenu;
    console.debug('👉🏽 showSubMenu', this.showSubmenu);
  }

  openTutorial() {
    this.menu.enable(false);
    Storage.set({
      key: 'ion_did_tutorial',
      value: 'false'
    });
    this.router.navigateByUrl('/tutorial');
  }

  updateTheme(value: boolean): void {
    this.dark = value;
    this.emitterService.action(AppInfoState.setTheme).emit(value as any);
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  menuLevel1 = null;
  menuLevel2 = null;
  menuLevel3 = null;
  public navPages: any = [];

  levelNav1(navX: string) {
    if (this.isNav1Displayed(navX)) {
      this.menuLevel1 = null;
    } else {
      this.menuLevel1 = navX;
    }
  }
  isNav1Displayed(navX: string) {
    return this.menuLevel1 === navX;
  }
  levelNav2(navX: string) {
    if (this.isNav2Displayed(navX)) {
      this.menuLevel2 = null;
    } else {
      this.menuLevel1 = navX;
      this.menuLevel2 = navX;
    }
  }
  isNav2Displayed(navX: string) {
    return this.menuLevel2 === navX;
  }
  levelNav3(navX: string) {
    if (this.isNav3Displayed(navX)) {
      this.menuLevel3 = null;
    } else {
      this.menuLevel2 = navX;
      this.menuLevel3 = navX;
    }
  }
  isNav3Displayed(navX: string) {
    return this.menuLevel3 === navX;
  }
  clearAccordionNav() {
    this.menuLevel1 = null;
    this.menuLevel2 = null;
    this.menuLevel3 = null;
  }

  handleBackButton() {
    this.platform.backButton.subscribeWithPriority(10, async () => {
      if (this.router.url === '/app/tabs/home' || this.router.url === '/login') {
        const currentTime = new Date().getTime();
        
        if (currentTime - this.lastBackPress < this.exitDelay) {
          navigator['app'].exitApp(); // Close the app
        } else {
          this.lastBackPress = currentTime;
          this.showToast('Press back again to exit');
        }
      } else {
        window.history.back(); // Navigate back
      }
    });
  }

  async showToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      position: 'bottom',
    });
    toast.present();
  }

}
